import logoSvg from './img/O.png'
import './App.css';
import background from './img/background.webp';
import sbImg from './img/bookmarks.png'
import './css/bootstrap.min.css';
import { useEffect, useState } from 'react'
import Footer from './footer';
import VideoImg from './img/ VideoDemo.jpg';

function App() {
  const style = {
    bg: {
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      height: "auto",
      minHeight: "100vh"
    }
  }
  const infos = ["#freelance", "#contact", "#freelance", "#contact", "#freelance", "#contact"];
  const colorsBG = ["bg-primary", "bg-success"];
  const colorsText = ["text-white", "text-warning"];
  const [infoIndex, setInfoIndex] = useState(0);
  const showNextInfo = () => {
    let c = infoIndex + 1;
    if (c >= infos.length) {
      c = 0;
    }
    setInfoIndex(c);
  }
  useEffect(() => {
    // Met à jour le titre du document via l’API du navigateur
    const timer = setTimeout(() => {
      showNextInfo();
    }, 2000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });
  return (
    <div className="App container-fluid d-flex flex-column" style={style.bg}>
      <div className="flex-fill">
        <div className="mb-4 opacity-100">&nbsp;</div>
        <img src={logoSvg} className="App-logoX img-fluid rounded" alt="logo" width="92px" height="92px" />
        {/* <div className="m-2 opacity-100">
          {infoIndex % 3 === 0 && <img src={logoSvg} className="App-logoX img-fluid rounded" alt="logo" width="92px" height="92px" />}
          {infoIndex % 3 === 1 && <img src={logo2} className="App-logoX img-fluid rounded" alt="logo" width="92px" height="92px" />}
          {infoIndex % 3 === 2 && <img src={logo3} className="App-logoX img-fluid rounded" alt="logo" width="92px" height="92px" />}
        </div> */}
        <p className="text-white">The right angle.</p>
        <div className="row g-1">
          <div className="col-sm-1">
          </div>
          <div className="col-sm-4">
            <div className="card shadow bg-light" style={{ height: "400px" }}>
              <div className="card-header">
                Outils pour le cabinet dentaire
              </div>
              <a href="https://youtu.be/J9-8GfLD4ns" target="_blank"><img width="100%"  src={VideoImg} alt=""></img></a>
                <div className="card-body">
                <p className="card-text">Analyses céphalométriques et suivi patients.</p>
                <a href="https://ortho.ortosoft.fr" className="btn btn-primary" target="_blank" rel="noreferrer">Connexion</a>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
          </div>
          <div className="col-sm-4">
            <div className="card shadow bg-light" style={{ height: "400px" }}>
              <div className="card-header">
                Gestionnaire des favoris
              </div>
              <img src={sbImg} className="img-fluid card-img-top overflow-auto" alt="..." />
              <div className="card-body">
                <div className="my-auto">
                  <h5 className="card-title">Simple bookmarks</h5>
                  <p className="card-text">Retrouvez vos favoris d'une manière simple et écologique. </p>
                  <a href="https://sb.ortosoft.fr" className="btn btn-primary" target="_blank" rel="noreferrer">Connexion</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-1">
          </div>
        </div>
      </div>

      <Footer></Footer>

      <div style={{ position: "absolute", right: "15px", top: "15px" }}>
        <div className="row opacity-75">
          <div className="col">
          <a className="btn btn-light" href="tel:0679217672"  rel="noreferrer" role="button">
            <svg  xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
          </svg></a>
          <a href="mailto:contact@ortosoft.fr" target="_blank" rel="noreferrer" className={"btn  " + colorsText[infoIndex % 2] + " " + colorsBG[infoIndex % 2]}>
            {infos[infoIndex]}
          </a>
          </div>
        </div>
      </div>
    </div>

  );
}

export default App;

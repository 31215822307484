import { Link } from 'react-router-dom';
import Footer from './footer';
import background from './img/background.webp';
import logoSvg from './img/O.png'

export default function ML() {
  const style = {
    bg: {
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      height: "auto",
      minHeight: "100vh"
    }
  }
  return (
    <div className="App container-fluid d-flex flex-column" style={style.bg}>
      <div className="container">
       <div className="flex-fill"> 
       <div className="mb-4 opacity-100">&nbsp;</div>
        <Link to='/'><img src={logoSvg} className="App-logoX img-fluid rounded" alt="logo" width="92px" height="92px" />
        </Link>

        <div className="text-left">
          <div className='row'>
            <div className="col-12"></div>
          </div>
          <div className='row'>
            <div className="col-12"></div>
          </div>
          <div className='row'>
            <div className="col-12 text-white"><h5>Mentions légales</h5></div>
          </div>
          <br>
          </br>
          <div className="row">
            <div className="card col-12">
              <div className="card-header">
                OrtoSoft
              </div>
              <div className="card-content text-flow">
                SASU au capital de 2000€
                <br></br>
                22 rue Paul Henry THILLOY
                <br></br>92340 Bourg La Reine
                <br></br><br></br>
                <p></p>Contact : 0679217672 / contact@ortosoft.fr
                <br></br>Directeur publication : Bogdan SCANTEIE
                <br></br>N° SIRET : 90439925000011/ RCS NANTERRE 904399250 / APE : 6201Z
                <br></br>N° TVA intracommunautaire : FR92 904399250
              </div>
            </div>
          </div>
          <p></p><p></p>
          <div className="row">
            <div className="card col-12">
              <div className="card-header">
                Cookies
              </div>
              <div className="card-content text-flow">
                Nous utilisons pas de cookies sur ce site.
                <br>
                </br>
                Lors de la connexion à nos produits, nous utilisons des cookies de connexion.
                <p>En vous connectant vous acceptez l'utilisation de ces cookies.</p>
                <h4>Contenu embarqué depuis d'autres sites</h4>
                Les articles de ce site peuvent inclure des contenus intégrés (par exemple des vidéos, images, articles…). Le contenu intégré depuis d'autres sites se comporte de la même manière que si le visiteur se rendait sur cet autre site.

                Ces sites web pourraient collecter des données sur vous, utiliser des cookies, embarquer des outils de suivis tiers, suivre vos interactions avec ces contenus embarqués si vous disposez d'un compte connecté sur leur site web.
<br></br>
                <b>Ortosoft</b> vous informe qu'il n'exerce aucun contrôle sur le contenu de sites de tiers ou sur les pratiques de ces tiers en matière de protection des données à caractère personnel qu'ils pourraient recueillir.
<br></br>
                En conséquence, <b>Ortosoft</b> décline toute responsabilité concernant le traitement par ces tiers de vos données à caractère personnel.

              </div>

            </div>

            <p></p>
            <div className="row">
              <div className="card col-12">
                <div className="card-header">
                  Conditions générales de vente
                </div>
                <div className="card-content text-flow">
                  <a href="cgv.pdf" target="_blank">Conditions generales de vente</a>&nbsp;&nbsp;
                </div>
              </div>
            </div>

            <p></p>
            <div className="row">
              <div className="card col-12">
                <div className="card-header">
                  Hébergeur
                </div>
                <div className="card-content text-flow">
                  <a href="https://www.interserver.net/about/" target="_blank">Interserver</a>&nbsp;&nbsp;
                  <br>
                  </br>
                </div>
              </div>
            </div>
            <br></br><br></br>
            <p></p><p></p>
            <Footer></Footer>
          </div>
        </div>
       </div> 
      </div>
    </div >
  );
}